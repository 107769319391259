import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  // connect() {
    // alert('conntected to modal')
    // this.modal = new bootstrap.Modal(this.element)
  // }

  // close(event) {
  //   if (event.detail.success) {
  //     var modal_id = event.currentTarget.dataset.modalId
  //     alert(`${ modal_id }`)
  //     // this.modal.hide()
  //     var modal = new bootstrap.Modal(document.getElementById(modal_id))
  //     if (modal) {
  //       modal.hide();
  //     } else {
  //       // alert('no modal')
  //     }
  //   }
  // }
}
