import { Controller } from "@hotwired/stimulus"
import NestedForm from '@stimulus-components/rails-nested-form'
export default class extends NestedForm {
  static targets = [ 'links', 'template' ]

  connect() {
    super.connect()
    console.log('do what you want here')
    // this.wrapperClass = this.data.get('wrapperClass') || 'nested-form-wrapper'
  }

  // add_association(event) {
  //   event.preventDefault()
  //   var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
  //   this.linksTarget.insertAdjacentHTML('beforebegin', content)
  // }

  // remove_association(event) {
  //   event.preventDefault()
  //   let wrapper = event.currentTarget.closest(`.${ this.wrapperClass }`)
  //   // console.log(`wrapper: ${ wrapper.dataset.newRecord }`)

  //   // New records are simply removed from the page
  //   if (wrapper.dataset.newRecord == 'true') {
  //     wrapper.remove()

  //   // Existing records are hidden and deleted instantly
  //   } else {
  //     // console.log(`b`)
  //     let csrf = document.getElementsByName('csrf-token')[0].content;
  //     let formIndex = event.currentTarget.dataset.formIndex
  //     console.log(`formIndex: ${ formIndex }`)
  //     // IT SEEMS METHOD DELETE IS GETTING PASSED TO THE PROJECTS CONTROLLER TOO, AND INSTEAD OF /SHOW IM GETTING /DESTROY ON THE PROJECT TOO

  //     fetch(event.currentTarget.dataset.destroyPath, {
  //       method: 'DELETE',
  //       dataType: 'script',
  //       credentials: 'include',
  //       headers: {
  //         'X-CSRF-Token': csrf
  //       },
  //     })

  //     // console.log(`${ event.currentTarget.dataset.childModelName }_attributes_${ formIndex }_id`)
  //     if (event.currentTarget.dataset.parentModelName == null || event.currentTarget.dataset.parentModelName == undefined) {
  //       var el = document.getElementById(`${ event.currentTarget.dataset.childModelName }_attributes_${ formIndex }_id`)
  //     } else {
  //       console.log(`${ event.currentTarget.dataset.parentModelName }_${ event.currentTarget.dataset.childModelName }_attributes_${ formIndex }_id`)
  //       var el = document.getElementById(`${ event.currentTarget.dataset.parentModelName }_${ event.currentTarget.dataset.childModelName }_attributes_${ formIndex }_id`)
  //     }
      
  //     el.remove();
  //     wrapper.remove();
  //   }
  // }
}
