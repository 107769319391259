import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['category']

  connect() {
    // alert('open');
    this.showHideServiceDocumentFields()
  }

  showHideServiceDocumentFields() {
    var card = document.getElementById('service_document_fields_wrapper');

    if (this.category == 'airworthiness_directive' || this.category == 'service_bulletin' || this.category == 'supplimental_type_certificate') {
      // alert('open');
      card.classList.remove('closed');
      card.classList.add('opened');
    } else {
      // alert('close');
      card.classList.remove('opened');
      card.classList.add('closed');
    }

  }

  get category() {
    return this.categoryTarget.value
  }

}
