import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()

    // Grab a reference to the toolbar(s) on the page.
    const toolbar = this.element.previousSibling
    // HTML for our buttons
    // <button type="button" class="trix-button" data-trix-attribute="h1Heading" title="Subheading H1">H1</button>

    const underlineBtn = '<button type="button" class="trix-button" data-trix-attribute="underline" title="underline">u</button>'
    const h2Btn = '<button type="button" class="trix-button" data-trix-attribute="h2Heading" title="Subheading H2">H2</button>'
    const h3Btn = '<button type="button" class="trix-button" data-trix-attribute="h3Heading" title="Subheading">H3</button>'
    // const indentBtn = '<button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-attribute="indent" data-trix-action="increaseNestingLevel" title="#{lang.indent}" tabindex="-1">#{lang.indent}</button>'
    // const redBtn = '<button type="button" class="trix-button" data-trix-attribute="red" title="red">RED</button>'
    // const greenBtn = '<button type="button" class="trix-button" data-trix-attribute="green" title="green">GREEN</button>'

    const newButtons = underlineBtn + h2Btn + h3Btn

    // Only apply event listeners once to the toolbars
    const once = {
      once: true
    }

    // addEventListener("trix-initialize", function(event) {
        
    //   const sibling1 = toolbar.querySelector(".trix-button--icon-increase-nesting-level")
    //   sibling1.insertAdjacentHTML("afterend", newButtons)

    // }, once)

  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: "name",
      values: this.fetchUsers,
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
    fetch('/mentions.json?query=${text}')
    .then(response => response.json())
    .then(users => callback(users))
    .catch(error => callback([]))
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()

    if (position == endPos)
    	this.editor.setSelectedRange([startPos, position])
    else
    	this.editor.setSelectedRange([(position - (endPos - startPos)), position])
    	// this.editor.setSelectedRange([position - endPos, position])

    this.editor.deleteInDirection("backward")
  }
}
