import { Controller } from "@hotwired/stimulus"
// import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ['name', 'disable', 'param_name', 'selected']

//   connect() {
//     var config = {};
//     // new TomSelect('#tom-select-it', config);

//   // enableTS() {
//   //   new TomSelect("#invoice_customer_id", {
//   //     create: function(input, _callback) {
//   //       myModal = new Modal(document.getElementById('customer-modal'), {
//   //         keyboard: false
//   //       });
//   //       myModal.show();
//   //       const name = document.getElementById('customer_name');
//   //       name.value = input;
//   //     }
//   //   });
//   // }
//   }

//   greet() {
//     console.log(`Hello, ${ this.name }!`)

//     if (this.disable == '') {
//       this.disableTarget.value = this.name.toLowerCase().split(' ').join('_');
//     }

//     if (this.nameTarget.dataset.exists == 'true') {
//       alert('inside exists');
//     }

//     // this.selectedTarget
//     // var e = document.getElementById("ddlViewBy");
//     var e = this.selectedTarget;
//     // var strUser = e.options[e.selectedIndex].value;
//     // alert(e.options[e.selectedIndex].value);

//     e.addEventListener('change', function(){
//       // alert(this.selected);
//       // alert(e.options[e.selectedIndex].value);
//     });

//     this.disableTarget.setAttribute("disabled", "disabled");
//   }

// // - - - - - - - - -
// // initialize() {
// //     this.nameElement.value = this.name
// //   }

//   log(event) {
//     event.preventDefault();
//     console.log(this.nameElement.value);
//   }

//   paste(event) {
//     event.preventDefault()
//     console.log("pastes are not allowed")
//   }

//   get name() {
//     if (this.data.has("name")) {
//       return this.data.get("name")
//     } else {
//       return "Default User"
//     }
//   }

//   get nameElement() {
//     return this.targets.find("name")
//   }
// // - - - - - - - - -


//   // get name() {
//   //   return this.nameTarget.value
//   // }

//   get disable() {
//     return this.disableTarget.value
//   }

//   // get selected() {
//   //   return this.selectTarget.options[e.selectedIndex].value
//   // }

//   // get param_name() {
//   //   return this.paramNameTarget.value
//   // }

}
