import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [  ]

  connect() {
    // console.log('who', 'dat');
    // console.log('classes', this.element.classList);
    // this.element.scrollLeft = 800;
    // document.getElementById('schedule').scrollLeft += 20;
  }

}




