import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['destroyAttachmentLink']

  displayDeleteProgress(event) {
    event.currentTarget.value = '...deleting';
    event.currentTarget.readOnly = true;
    event.currentTarget.classList.add('text-muted')
  }

  // create(event) {
  //   var csrf = document.getElementsByName('csrf-token')[0].content;
  //   var record_model = event.currentTarget.dataset.recordModel;

  //   // var record_id = event.currentTarget.dataset.recordId;
  //   // console.log(`record_id: ${record_id}`)
  //   var path = event.currentTarget.dataset.createPath;
  //   // console.log(`path: ${path}`)

  //   if (event.currentTarget.hasAttribute('data-dom-id')) {
  //     var domId = event.currentTarget.dataset.domId;
  //     // console.log(`a: ${ domId }`)
  //   } else {
  //     // var domId = `${ record_model }_${ record_id }_li`
  //     // console.log(`b: ${ domId }`)
  //   }

  //   fetch(
  //     path, {
  //       method: 'POST',
  //       dataType: 'script',
  //       credentials: 'include',
  //       headers: {
  //         'X-CSRF-Token': csrf
  //       }
  //     }
  //   );
  // }

  destroy(event) {
    var csrf = document.getElementsByName('csrf-token')[0].content;
    var record_model = event.currentTarget.dataset.recordModel;
    var record_id = event.currentTarget.dataset.recordId;

    // console.log(`record_model: ${record_model}, record_id: ${record_id}`)
    // console.log(`record_id: ${record_id}`)
    var path = event.currentTarget.dataset.destroyPath;
    // console.log(`path: ${path}`)

    if (event.currentTarget.hasAttribute('data-dom-id')) {
      var domId = event.currentTarget.dataset.domId;
      console.log(`a: ${ domId }`)
    } else {
      var domId = `${ record_model }_${ record_id }_li`
      console.log(`b: ${ domId }`)
    }

    if (domId.length > 0 && record_model != 'favorite_record') {
      document.getElementById(domId).classList.add('d-none');
      // console.log(`should have hid: ${ domId }`)
    }

    fetch(
      path, {
        method: 'DELETE',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        }
      }
    );
  }

  hide(event) {
    var csrf         = document.getElementsByName('csrf-token')[0].content;
    var record_model = event.currentTarget.dataset.recordModel;
    var record_id    = event.currentTarget.dataset.recordId;
    var path         = event.currentTarget.dataset.updatePath;

    if (event.currentTarget.hasAttribute('data-dom-id')) {
      var domId = event.currentTarget.dataset.domId;
    } else {
      var domId = `${ record_model }_${ record_id }_li`
    }

    document.getElementById(domId).classList.add('d-none');

    fetch(
      path, {
        method: 'GET',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        }
      }
    );
  }

  approve_reject(event) {
    var csrf         = document.getElementsByName('csrf-token')[0].content;
    var record_model = event.currentTarget.dataset.recordModel;
    var record_id    = event.currentTarget.dataset.recordId;
    var path         = event.currentTarget.dataset.updatePath;

    if (event.currentTarget.hasAttribute('data-dom-id')) {
      var domId = event.currentTarget.dataset.domId;
    } else {
      var domId = `${ record_model }_${ record_id }_li`
    }

    document.getElementById(domId).classList.add('d-none');

    fetch(
      path, {
        method: 'GET',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        }
      }
    );
  }

  updateProjectCharge(event) {
    var csrf         = document.getElementsByName('csrf-token')[0].content;
    var record_model = event.currentTarget.dataset.recordModel;
    var record_id    = event.currentTarget.dataset.recordId;
    var path         = `${ event.currentTarget.dataset.updatePath }?${ event.currentTarget.dataset.formInputName }=${ event.currentTarget.value }`;

    fetch(
      path, {
        method: 'GET',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        }
      }
    );
  }

  createProjectCharge(event) {
    var csrf = document.getElementsByName('csrf-token')[0].content;
    var record_model = event.currentTarget.dataset.recordModel;
    var record_id = event.currentTarget.dataset.recordId;
    var val = event.currentTarget.value
    var path = `${ event.currentTarget.dataset.createPath }&amount=${ val }`;

    if (event.currentTarget.hasAttribute('data-dom-id')) {
      var domId = event.currentTarget.dataset.domId;
    } else {
      var domId = `${ record_model }_${ record_id }_li`
    }

    if (val != null) {
      fetch(
        path, {
          method: 'POST',
          dataType: 'script',
          credentials: 'include',
          headers: {
            'X-CSRF-Token': csrf
          }
        }
      );
    }
  }

  updateAttr(event) {
    var csrf         = document.getElementsByName('csrf-token')[0].content;
    var record_model = event.currentTarget.dataset.recordModel;
    var record_id    = event.currentTarget.dataset.recordId;
    var path         = `${ event.currentTarget.dataset.updatePath }?${ event.currentTarget.dataset.formInputName }=${ event.currentTarget.value }`;

    if (event.currentTarget.hasAttribute('data-dom-id')) {
      var domId = event.currentTarget.dataset.domId;
    } else {
      var domId = `${ record_model }_${ record_id }_li`
    }

    fetch(
      path, {
        method: 'GET',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrf
        }
      }
    );
  }

  swapOption(event) {
    var formIndex = event.currentTarget.dataset.formIndex;
    var selectorDomId = `selectDescription${ formIndex }`
    var textDomId = `textDescription${ formIndex }`

    // console.log(`classList: ${ event.currentTarget.classList }`)
    // console.log(`formIndex: ${ formIndex }`)
    // console.log(`formIndex: ${ formIndex }, selectorDomId: ${ selectorDomId }, textDomId: ${ textDomId }`)

    document.getElementById(selectorDomId).classList.add('d-none');
    document.getElementById(textDomId).classList.remove('d-none');
  }

  // swapOption(event) {
  //   var formIndex = event.currentTarget.dataset.formIndex;
  //   var selectorDomId = `selectDescription${ formIndex }`
  //   var textDomId = `textDescription${ formIndex }`
  //   var add_item_box = document.getElementById(textDomId)
  //   var add_box_parent = document.getElementById(selectorDomId)

  //   if (add_item_box.disabled) {
  //     add_box_parent.classList.add('d-none');
  //     add_item_box.classList.remove('d-none');
  //     add_item_box.disabled = false
  //   } else {
  //     add_box_parent.classList.remove('d-none');
  //     add_item_box.classList.add('d-none');
  //     add_item_box.disabled = true
  //   }

  // }

}
