// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import "chartkick/chart.js"

import 'trix'

Trix.config.textAttributes.highlight = { tagName: 'mark' };
addEventListener('trix-initialize', function(event){
  var groupElement = event.target.toolbarElement.querySelector('.trix-button-group.trix-button-group--text-tools')
  groupElement.insertAdjacentHTML('beforeend', '<button type="button" class="trix-button trix-button--icon trix-button--icon-hightlight" data-trix-attribute="highlight" data-trix-key="y" title="Highlight" tabindex="-1">highlight')
})

// window.initPlaces = function(...args) {
//   const event = document.createEvent("Events")
//   event.initEvent("google-maps-callback", true, true)
//   event.args = args
//   window.dispatchEvent(event)
// }

import '@rails/actiontext'
